<template>
  <div class="pageCenter">
    <el-backtop></el-backtop>
    <!-- headerPicture -->
    <div class="heardePicture">
      <div class="heardePicture_text">
        <h1>慧同行APP</h1>
        <p>
          慧同行APP是一款将公交服务拓展到用户手机的应用，用户
          可以通过手机扫码乘车、随时随地查询公交线路、车辆相关信
          息，让用户享受智慧出行带来的便捷。
        </p>
        <div>
          <el-button type="warning" round>iOS下载</el-button>
          <el-button type="warning" round>Android下载</el-button>
        </div>
      </div>
      <div class="heardePicture_menu">
        <ul>
          <li @click="selectPage_1">
            <span v-if="chooseItem"
              ><img src="../../assets/image/产品服务/大数据云平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="../../assets/image/产品服务/大数据云平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_2">
            <span v-if="chooseItem"
              ><img src="../../assets/image/产品服务/安全平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="../../assets/image/产品服务/安全平台.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_3">
            <span v-if="chooseItem"
              ><img src="../../assets/image/产品服务/大数据-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="../../assets/image/产品服务/大数据.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_4">
            <span v-if="chooseItem"
              ><img src="../../assets/image/产品服务/APP出行平台-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="../../assets/image/产品服务/APP出行平台-选中.png" alt=""
            /></span>
          </li>
          <li @click="selectPage_5">
            <span v-if="chooseItem"
              ><img src="../../assets/image/产品服务/充电桩-选中.png" alt=""
            /></span>
            <span v-else
              ><img src="../../assets/image/产品服务/充电桩.png" alt=""
            /></span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 智慧安全平台 -->
    <div class="busApp">
      <section id="about-us" class="about-us">
        <!-- 标题 -->
        <h2 class="title1">慧同行APP</h2>
        <!-- 补充说明区域 -->
        <p class="intro">公交出行APP为乘客提供便捷智能的公共出行服务</p>
        <p class="intro_2">更多功能陆续上线</p>
        <!-- 公司业务容器，栅格布局 -->
        <div class="appCard">
          <div class="appCard_item1">
            <img
              src="../../assets/image/产品服务/APP-出行平台/扫码乘车-icon.png"
              alt=""
            />
            <h3>扫码乘车</h3>
            <p>一体化在线支付</p>
          </div>
          <div class="appCard_item2">
            <img
              src="../../assets/image/产品服务/APP-出行平台/实时公交-icon.png"
              alt=""
            />
            <h3>实时公交</h3>
            <p>公交信息在线查询</p>
          </div>
          <div class="appCard_item3">
            <img
              src="../../assets/image/产品服务/APP-出行平台/网上营业厅-icon.png"
              alt=""
            />
            <h3>网上营业厅</h3>
            <p>实体卡业务线上申办</p>
          </div>
          <div class="appCard_item4">
            <img
              src="../../assets/image/产品服务/APP-出行平台/线路规划-icon.png"
              alt=""
            />
            <h3>线路规划</h3>
            <p>多换乘方案对比</p>
          </div>
        </div>
        <div class="busApp_bottom"></div>
      </section>
    </div>
    <!-- 实时监控 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="../../assets/image/产品服务/APP-出行平台/实时公交-配图.png"
          alt=""
        />
        <div class="realTime_right">
          <div class="realTime_title">
            <h2>实时公交</h2>
          </div>
          <p class="realTime_content">
            实时查看附近站点及线路信息推荐
            提供公交到站时间、到站距离、停靠站点、拥挤度等信息 告别等车焦虑助力乘客高效管理出行时间让用户享受智慧出行带来的便捷
          </p>
        </div>
      </div>
    </div>
    <!-- 网上营业厅 -->
    <div class="businessHall">
      <div class="businessHall_title">
        <h2>网上营业厅</h2>
        <!-- <img src="../../assets/image/产品服务/安全平台/标题-bg.png" alt=""> -->
      </div>
      <p class="businessHall_content">
        以用户为中心，提供多场景化在线服务线上充值、申办、年审、挂失
        为乘客提供公交卡“全栈式”智慧出行服务
        实现公共交通业务与互联网相互融合，使出行更便捷
      </p>
      <img
        src="../../assets/image/产品服务/APP-出行平台/网上营业厅-配图.png"
        alt=""
      />
    </div>
    <!-- 线路规划 -->
    <div class="realTime">
      <div class="realTime_box">
        <img
          class="realTimeP"
          src="../../assets/image/产品服务/APP-出行平台/实时公交-配图.png"
          alt=""
        />
        <div class="realTime_right">
          <div class="realTime_title">
            <h2>线路规划</h2>
          </div>
          <p class="realTime_content">
            精准线路规划及多场景线路推荐<br />提供多种换乘方案对比<br />
            线路详情及可视化地图相结合<br />提升城市公共交通吸引力<br />让出行更简单
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chooseItem: ''
    }
  },
  methods: {
    selectPage_1() {
      this.$router.push('/productService')
      window.scrollTo(0, 0)
    },
    selectPage_2() {
      this.$router.push('/securityPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_3() {
      this.$router.push('/monitoringPlatform')
      window.scrollTo(0, 0)
    },
    selectPage_4() {
      this.$router.push('/travelApp')
      window.scrollTo(0, 0)
    },
    selectPage_5() {
      this.$router.push('/chargingPile')
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="less" scoped>
.heardePicture {
  position: relative;
  width: 100%;
  height: 1000px;
  background: url('../../assets/image/产品服务/APP-出行平台/banner.png') 0;
  .heardePicture_text {
    position: absolute;
    top: 285px;
    left: 285px;
    width: 480px;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    p {
      font-size: 24px;
      margin-bottom: 70px;
      color: #fefefe;
    }
  }

  .heardePicture_menu {
    z-index: 999;
    position: fixed;
    top: 222px;
    right: 100px;
    ul {
      width: 124px;
      li {
        width: 124px;
        text-align: center;
      }
    }
  }
}
.busApp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 掌上公交APP */
.about-us {
  position: relative;
}

.appCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 280px;
  .appCard_item1,
  .appCard_item2,
  .appCard_item3,
  .appCard_item4 {
    width: 316px;
    height: 376px;
    background: url('../../assets/image/产品服务/APP-出行平台/扫码乘车-bg.png');
    background-size: cover;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin-top: 90px;
      font-size: 24px;
      color: #111;
    }
    p {
      font-size: 18px;
      color: #999;
    }
  }
  .appCard_item2 {
    background: url('../../assets/image/产品服务/APP-出行平台/实时公交-bg.png');
  }
  .appCard_item3 {
    background: url('../../assets/image/产品服务/APP-出行平台/网上营业厅-bg.png');
  }
  .appCard_item4 {
    background: url('../../assets/image/产品服务/APP-出行平台/线路规划-bg.png');
  }
}
.busApp_bottom {
  margin-top: 287px;
  width: 100%;
  height: 266px;
  background: url('../../assets/image/产品服务/APP-出行平台/掌上公交APP-bg.png');
}

/* 所有section都按grid布局 */
section {
  display: grid;
  /* 单元格都居中对齐 */
  justify-items: center;
  width: 100%;
}

/* 区域大标题 */
.title1 {
  margin-top: 100px;
  /* justify-self: center; */
  /* position: relative; */
  font-size: 36px;
  color: #111;
}
.intro {
  font-size: 24px;
  color: #999;
  margin-top: 10px;
}
.intro_2 {
  font-size: 18px;
  color: #f2ab3c;
  margin-top: 10px;
}

.realTime {
  background: #f3f3f8;
  padding: 80px 0;
  height: 967px;
  // .realTime_box {
  //   display: grid;
  //   /* 规划栅格，图标占两行，标题和内容各占一行 */
  //   grid-template-areas:
  //     'img title'
  //     'img content';
  //   /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
  //   grid-template-columns: 1100px 1fr;
  //   /* 三行 */
  //   grid-template-rows: 1fr 2fr;
  //   margin-left: 120px;
  //   .realTimeP {
  //     grid-area: img;
  //   }
  //   .realTime_title {
  //     h2 {
  //       font-size: 36px;
  //       color: #111;
  //       margin-top: 66px;
  //     }
  //     grid-area: title;
  //     background: url('../../assets/image/产品服务/APP-出行平台/标题-bg.png')
  //       no-repeat;
  //   }
  //   .realTime_content {
  //     width: 537px;
  //     grid-area: content;
  //     font-size: 24px;
  //     color: #666;
  //   }
  // }
  .realTime_box {
    display: flex;
    flex-direction: row;
    padding-left: 150px;
    img {
      width: 1200px;
      height: 987px;
    }
    .realTime_right {
      margin-left: -200px;
      .realTime_title {
        height: 145px;
        padding-top: 1px;
        padding-left: 10px;
        background: url('../../assets/image/产品服务/APP-出行平台/标题-bg.png')
          no-repeat;
        h2 {
          font-size: 36px;
          color: #111;
          margin-top: 66px;
        }
      }
      .realTime_content {
        width: 537px;
        font-size: 24px;
        color: #666;
        line-height: 40px;
      }
    }
  }
}
.businessHall {
  padding: 80px 0 80px 400px;
  height: 700px;
  position: relative;
  .businessHall_title {
    height: 145px;
    padding-top: 66px;
    h2 {
      font-size: 36px;
      color: #111;
    }
    background: url('../../assets/image/产品服务/APP-出行平台/标题-bg.png')
      no-repeat;
  }
  .businessHall_content {
    width: 537px;
    grid-area: content;
    font-size: 24px;
    color: #666;
    line-height: 40px;
  }
  img {
    position: absolute;
    top: -250px;
    right: 50px;
  }
}
</style>
